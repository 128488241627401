<template>
<v-container>

      <v-row class="mt-4 icon-text">
        <v-col cols="6" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'ng.png'" width="100%" class="ma-auto" @click="$router.push('/number-games').catch(err => {})"/>
          </div>
          
        </v-col>
        <v-col cols="6" class="text-center">
          <div>
            <img :src="$store.state.img_dir+'cg.png'"  width="100%" class="ma-auto" @click="$router.push('/color-game').catch(err => {})"/>
          </div>
        </v-col>
       
      </v-row>
      <va-soon :show="how" @DialogEvent="hEvent"/>
</v-container>
</template>
<script>
//import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
   // mixins: [API_MIXINS],
    data: () => ({
      items: [
          {
            src:"banner.png",
          },
          {
            src:"banner.png",
          },
        ],
      watch: false,
      DRAWINTERVAL: null,
      next_draw:"",
      how:false,
      result: {},
        system: {},
    }),
    created() {
      this.PING()
        this.setData()
    },
     mounted(){
         this.setDrawer(false)
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.SYSTEM_UPDATE()
         
        }
        
    },
    computed:{
     
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
    
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading', 'setOverlayLoadingText', 'setOpenMachine','setWatchDraw', 'setLiveDrawStatus']),
    
      hEvent(){
        this.how= false
      },
    },
  }
</script>